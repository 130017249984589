<template>
  <div class="TearmAudit public-studioAdmin-body">
    <StudioAdminTabs :list="tabs" :newIndex="1" @change="tabsChange"></StudioAdminTabs>
    <!-- <div class="navC">
      <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;">
        <el-tab-pane label="成员管理" name="1"></el-tab-pane>
        <el-tab-pane :label="'成员审核（' + StudioObj.status_user + '）'" name="2"></el-tab-pane>
      </el-tabs>
    </div> -->
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop public-form-studioAdmin">
        <el-input v-model="form.search_name" placeholder="请输入姓名/单位" class="input"></el-input>
        <el-button type="primary" @click="TearmAuditTab()">查询</el-button>
        <el-button @click="clearData()">重置</el-button>
      </div>
      <!-- 表格 -->
      <div class="tableList">
        <el-table :data="tableData" :stripe="true" class="public-table-studioAdmin">
          <el-table-column label="序号" align="center" width="70">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="成员姓名" align="center" width="120">
            <template slot-scope="scope">
              <div class="user-info">
                <img :src="scope.row.photo_url" class="user-cover">
                <p class="user-text">{{ scope.row.realname }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="organization_name" label="单位" align="center" />
          <el-table-column prop="create_time" label="申请时间" align="center" width="180" />
          <el-table-column prop="reason" label="申请理由" align="center" />
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button type="text" class="text-color-blue" @click="handleWay(scope.row, 1)">同意</el-button>
              <el-button type="text" class="text-color-red" @click="handleWay(scope.row, 2)">拒绝</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="total" class="block">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
      <!-- 同意的弹框 -->
      <div class="TBox">
        <el-dialog title="角色分配" :visible.sync="TabVisible" @close="onCloseAccepter">
          <div class="roleBox">
            <p class="tip">
              您正在为
              <span>{{ userInfo.realname }}</span>
              分配角色，请勾选角色进行分配
            </p>
            <div class="Kbox" v-for="(item, index) in roleSelect" :key="index">
              <div class="cBox">
                <el-radio v-model="studio_id" :label="item.id">{{ item.name }}</el-radio>
                <!-- <el-checkbox v-model="item.isCheck">{{
                  item.name
                }}</el-checkbox> -->
              </div>
              <p>{{ item.dec }}</p>
            </div>
          </div>
          <div class="pl30 mb20" v-if="studio_id == 1">
            <el-radio-group v-model="radio">
              <el-radio v-for="item in roleList" :key="item.id" :label="item.id">{{ item.name }}</el-radio>
            </el-radio-group>
          </div>
          <div class="btn">
            <el-button class="one" @click="TabVisible = false">取消</el-button>
            <el-button type="primary" class="two" @click="AuditRole()">保存</el-button>
          </div>
        </el-dialog>

        <el-dialog title="拒绝申请" style="width: 1040px; margin: 0 auto" :visible.sync="TabVisible1" @close="onCloseRejeter">
          <div class="noBox">
            <p>你确认要拒绝 {{ userInfo.realname }}的申请吗？</p>
            <el-input class="inBox" type="textarea" :rows="6" placeholder="请输入拒绝的理由" v-model="form1.refusal"
              resize="none">
            </el-input>
          </div>
          <div class="btn">
            <el-button class="one" @click="TabVisible1 = false">取消</el-button>
            <el-button type="primary" class="two" @click="cancelWay">确定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 弹框数据
      userInfo: "",
      TabVisible: false,
      TabVisible1: false,
      input: "",
      tableData: [],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10,
        search_name: "",
        teaching_studio_id: "",
      },
      //申请表单
      form1: {
        id: "",
        state: "",
        refusal: "", //拒绝理由
        role_studio_id: "",
        role_studio_child_id: "",
      },
      studio_id: 3,
      //角色分配
      roleSelect: [
        {
          id: 2,
          isCheck: false,
          name: "骨干学员",
          dec: "骨干学员是工作室的重点培养对象，在四名的引领下提高个人教育教学能力。",
        },
        {
          id: 3,
          isCheck: false,
          name: "网络学员",
          dec: "网络学员是工作室的辐射对象，可以在线上参与工作室的研修。",
        },
        {
          id: 1,
          isCheck: false,
          name: "工作室管理员",
          dec: "工作室管理员具有管理工作室的权限，其管辖的权限可以在“角色管理”中设置。",
        },
      ],
      roleList: [
        { id: 1, name: "工作室助手", },
        { id: 2, name: "理论导师", },
        { id: 3, name: "指导顾问", },
        { id: 4, name: "教研员", },
      ],
      activeName: '2',
      radio: 1,
      StudioObj: {
        status_user: 0,
      },
      //导航
      tabs: [
        {
          label: "成员管理",
          count: 0
        },
        {
          label: "成员审核",
          count: 0
        }
      ],
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    this._idnexTeachingStudio()
    this.TearmAuditTab();
  },
  computed: {},
  methods: {
    //切换回调
    tabsChange(val) {
      if (val == 0) {
        this.$router.push({
          path: "/TearmAdmin"
        })
      }
    },
    async _idnexTeachingStudio() {
      let res = await this.$axios.get(
        "index/TeachingStudio/idnexTeachingStudio",
        { params: { teaching_studio_id: localStorage.getItem("studioId") } }
      );
      this.StudioObj = res.data.data
      this.tabs[1].count = this.StudioObj.status_user;
    },
    clearData() {
      this.form.page = 1;
      this.form.limit = 10;
      this.form.search_name = "";
      this.TearmAuditTab();
    },
    onCloseRejeter() {
      this.form1 = this.$options.data().form1;
    },
    onCloseAccepter() {
      this.roleSelect.map((e) => {
        e.isCheck = false;
      });
    },
    //同意
    handleWay(row, type) {
      this.userInfo = row;
      this.form1.id = row.id;
      this.form1.state = type;
      if (type == 1) {
        this.TabVisible = true;
      } else {
        this.TabVisible1 = true;
      }
      //  this.$axios.put('index/teachingStudioUser/changTeachingStudioUser',this.form1).then(()=>{
      //        if(type == 1){
      //          this.$message.success("已同意");
      //        }else{
      //          this.$message.success("已拒绝");
      //        }
      //  })
    },

    //拒绝申请
    cancelWay() {
      if (!this.form1.refusal.trim()) {
        return this.$message.warning("拒绝理由不能为空");
      }
      this.$axios.put("index/teachingStudioUser/changTeachingStudioUser", this.form1).then((res) => {
        this.TabVisible1 = false;
        this.$message.success(res.data.msg);
        this.TearmAuditTab();
        this._idnexTeachingStudio()
      });
    },

    //审核 同意
    AuditRole() {
      // let arr = [];
      // this.roleSelect.map((e) => {
      //   if (e.isCheck) {
      //     arr.push(e.id);
      //   }
      // });
      this.form1.role_studio_id = this.studio_id
      if (this.form1.role_studio_id != 1) {
        this.form1.role_studio_child_id = ""
      } else {
        this.form1.role_studio_child_id = this.radio
      }
      this.$axios.put("index/teachingStudioUser/changTeachingStudioUser", this.form1).then((res) => {
        this.$message.success("审批成功")
        //关闭弹窗
        this.TabVisible = false;
        //重新加载列表数据
        this.TearmAuditTab();
        this._idnexTeachingStudio()
        //   let state = res.data.msg;
        //   if (state == "操作失败") {
        //     this.$message.error(state);
        //   } else {
        //     //操作成功
        //     this.AddRole();
        //   }
      });
    },
    //角色添加
    // AddRole() {
    //   let obj = { id: this.userInfo.id, role_studio_id: [] };
    //   this.roleSelect.forEach((item) => {
    //     //判断是否选中，选中就放进数组里
    //     if (item.isCheck == true) {
    //       obj.role_studio_id.push(item.id);
    //     }
    //   });

    //   this.$axios
    //     .put("index/teachingStudioUser/roleTeachingStudioUser", obj)
    //     .then(() => {
    //       //关闭弹窗
    //       this.TabVisible = false;
    //       //重新加载列表数据
    //       this.TearmAuditTab();
    //       this.$message.success("分配角色成功");
    //     });
    // },

    //成员审核
    async TearmAuditTab() {
      this.form.teaching_studio_id = localStorage.getItem("studioId");
      let res = await this.$axios.get("index/teachingStudioUser/listTeachingStudioUserState", { params: this.form });
      this.tableData = res.data.data.data;
      this.total = res.data.data.total;
    },
    //分页
    handleSizeChange(val) {
      this.form.limit = val;
      this.TearmAuditTab();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.TearmAuditTab();
    },
  },
};
</script>
<style lang="less" scoped>
.TearmAudit {
  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: end;
    border-bottom: 1px solid #f0f0f0;
    justify-content: space-between;
    box-sizing: border-box;

    /deep/.el-tabs__header {
      margin: 0;

      .el-tabs__item {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .el-tabs__item.is-active {
        font-weight: bold;
      }
    }
  }

  //内容盒子
  .contentBox {
    padding: 26px 26px;

    .selectTop {
      .input {
        width: 216px;
        margin-right: 10px;
      }
    }

    //样式
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }

    //弹框
    .TBox {
      /deep/.el-dialog__header {
        border-bottom: 1px solid #ebebeb;
        font-weight: bold;
      }

      .roleBox {

        //第一行
        .tip {
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          margin-bottom: 30px;

          span {
            font-size: 16px;
            font-family: Microsoft YaHei Regular,
              Microsoft YaHei Regular-Regular;
            font-weight: 700;
            color: #333333;
          }
        }

        //勾选内容
        .Kbox {
          /deep/.el-checkbox__label {
            font-size: 16px;
            color: #333333;
          }

          p {
            width: 520px;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            color: #999999;
            margin-left: 27px;
            margin-bottom: 25px;
            margin-top: 12px;
          }
        }
      }

      .roleBox1 {
        width: 280px;
        margin: 0 auto;

        //第一行
        .tx1 {
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          margin-bottom: 14px;

          i {
            color: #ff8201;
            font-size: 18px;
          }

          span {
            font-weight: 700;
          }
        }

        //第二行
        .tx2 {
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          color: #999999;
          margin-bottom: 42px;
        }
      }

      //按钮
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;

        .one {
          width: 148px;
          height: 42px;
          background: #f7f7f7;
          border: 1px solid #ececec;
          border-radius: 6px;
          font-size: 16px;
          font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
          font-weight: 700;
          color: #333333;
        }

        .two {
          width: 148px;
          height: 42px;
          background: #ff8201;
          border-radius: 6px;
          border: 1px solid #ff8201;
          font-size: 16px;
          font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
          font-weight: 700;
          color: #ffffff;
        }
      }

      //拒绝的盒子
      .noBox {
        width: 80%;
        margin: 0 auto;

        p {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        .inBox {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.user-info {
  display: flex;
  align-items: center;

  .user-cover {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 8px;
  }

  .user-text {
    font-size: 16px;
    color: #333333;
    flex: 1;
    overflow: hidden;
  }
}
</style>
